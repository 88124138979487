
/**
 * ppga vaulting 选中态
 */
export const PPGA_CHECK_TYPE = {
  'CHECKED': '1',
  'UNCHECKED': '0'
}

/**
 * AfterPay vaulting 选中态
 */
export const AFTERPAY_CHECK_TYPE = {
  'CHECKED': '1',
  'UNCHECKED': '0'
}
